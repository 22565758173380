.DataAnalyticsCont{
    width: 100%;
    height: 100vh;
    background-image: url('../../Assets/data4.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.DataAnalyticsCont .subMain{
    width: 50%;
    /* border: 1px solid black; */
    margin-left: 5rem;
}
.DataAnalyticsCont .subMain h1{
    margin: auto;
    font-size: 41px;
    font-weight: 700;
    color: white;
    line-height: 41px;
    font-family: Inter;
}


