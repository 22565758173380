/*=----------------------- Navbar ------------------------------=*/

.parent{
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  position: fixed;
  top: 0;
 
}
.parent_active{
  background-color: white;
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  position: fixed;
  top: 0;
}
.logo{
  /* border: 1px solid black; */
  margin-left: 2rem;
  display: flex;
  justify-content: space-around;
 
  /* gap:5px; */
align-items: center;
}
.logo>img{
  width: 70px;
  height:50px;
}

.side1_active{
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  gap:30px;
  
}
.side1_active h3{
  color: #003358;
 
}
.side1{
  display: flex;
  justify-content: space-between;
  margin-right: 2rem;
  gap:30px;
 
}
.side1 h3{
  color: white;
}





/* Media query for screens smaller than 768px */
/* @media (max-width: 768px) {
  .parent {
    flex-direction: column;
    padding: 10px 0;
  }

  .side1 {
    flex-direction: column;
    margin-top: 10px;
    margin-right: 0;
    display: none;
    color: #003358;
  }

  .side1 h3 {
    margin: 10px 0;
  }
} */

/*=-------------------------------------- Home-------------------------------------= */

.container{
    width: 100%;
    height: 100vh;
    background-image: url('../Assets/robot2.jpeg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}


.container .sub-cont1{
    width: 50%;
    /* border: 1px solid black; */
    margin-left: 5rem;
}
.container .sub-cont1 h1{
    margin: auto;
    font-size: 41px;
    font-weight: 700;
    color: white;
    line-height: 41px;
    font-family: Inter;
}
.container .sub-btn{
    background-color:#007aff;
    border: none;
    width: 150px;
    border-radius: 4px;
    padding: 5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 38px;
    margin-top: 40px;
}
.container .sub-cont2 img{
    margin: auto;
    width: 85%;
}
.cont-parent{
    width: 90%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-top: 80px;
}
.cont-parent .cont{
    margin: auto;
    width: 45%;
}
.cont-parent .cont .title{
    color: #ff3a2d;
    font-size: 43px;
    font-weight: 500;
    font-family: Inter, sans-serif;
}
.cont-parent .cont .text{
    font-size: 16px;
    font-family:  Roboto, sans-serif;
    font-weight: 400;
    color: rgb(0,0,0);
    line-height: 26.56px;
}
.cont-parent .cont2{
    margin: auto;
    width: 50%;
   margin-left: 12%; 
}
.cont-parent .cont2 .title2{
    color: #ff3a2d;
    font-size: 43px;
    font-weight: 500;
    font-family: Inter, sans-serif;
    margin-left: 50px;
}
.cont-parent .cont2 .text2{
    font-size: 16px;
    font-family:  Roboto, sans-serif;
    font-weight: 400;
    color: rgb(0,0,0);
    line-height: 26.56px;
    margin-left: 50px;
}
.cont-parent .img{
    margin: auto;
    width: 45%;
    /* border:1px solid black; */
   
}
.cont-parent .img img{
  width: 105%;
    margin-right: 50px;
   height:80%;

}
.cont-parent .img2{
    margin: auto;
    width: 45%;
}
.cont-parent .img2 img{
    width: 650px;
    margin-top: 20px;
    
    
}
 .btn{
    border: none;
    width: 120px;
    background-color: #ff3a2d;
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    border-radius: 4px;
    padding: 15px;
    font-family:Roboto,sans-serif ;
    margin-top: 20px;
}
.btn2{
    border: none;
    width: 120px;
    background-color: #ff3a2d;
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    border-radius: 4px;
    padding: 15px;
    font-family:Roboto,sans-serif ;
    margin-left: 50px;
    margin-top: 20px;
}
@media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .container .sub-cont1 {
      width: 80%; 
      margin-left: 0;
      text-align: start;
    }
  
    .container .sub-cont2 img {
      width: 100%; 
      
    }
  
    .cont-parent {
      flex-direction: column-reverse;
    }
  
    .cont-parent .cont,
    .cont-parent .cont2,
    .cont-parent .img,
    .cont-parent .img2 {
      width: 100%;
      
      
    }
  
    .cont-parent .cont .title,
    .cont-parent .cont .text,
    .cont-parent .cont2 .title2,
    .cont-parent .cont2 .text2 {
      margin-left: 0;
      text-align: start;
    }
  
    .cont-parent .img img,
    .cont-parent .img2 img {
      width: 100%; 
      margin-right: 0;
      
    }
  }

/* =----------------------------------------Footer------------------------= */
.foot-cont {
    background-color: #081528;
    width: 100%;
    height: 55vh;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .foot-cont .ft-img {
    margin-left: 2rem;
  display: flex;
  justify-content: space-around;
 
  /* gap:5px; */
align-items: center;
  }
  
  .foot-cont .ft-img img {
    width: 70px;
  height:60px;
  }
  
  .foot-cont .ft-title {
    color: white;
    display: flex;
    justify-content: center;
    width: 500px;
    margin-left:20px ;
  }
  
  .foot-cont .ft-title h3 {
    margin-right: 3rem;
    font-size: 16px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    
  }
  
  .foot-cont .ft-text p {
    font-size: 16px;
    font-weight: 400;
    color: rgb(111, 127, 146);
    text-align: center;
  }
  @media (max-width: 768px){
    .foot-cont .ft-title h3 {
        margin-right: 1rem;
    }
    .foot-cont .ft-text p {
        font-size: 10px;
    }
  }
  /* ////////////////////// */
 


.navbar-item p {
    text-decoration: none;
    color: white;
    display: flex;
    transition: color 0.3s ease;
    align-items: center;
    position: relative;
}
.navbar-item  {
display: flex;
  flex-direction: row;
 
}

.navbar-item.active p {
    color: black;
}

.dropdown {
    width: 160px;
    position: absolute;
    /* top: 100%; */
    left: 1;
    display: block !important;
    background-color: white;
    padding: 18px;
    border-radius: 2px;
    z-index: 1;
    margin-top: 25px;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.176);
}

.navbar-item:hover .dropdown {
    display: block;
}

.dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  
}

.dropdown-menu li {
    margin-bottom: -15px;
    margin-top: -15px;
    /* border:2px solid pink; */
    
   
}

.dropdown-menu p {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
    
  }

.navbar-item p::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -5px;
    background-color: #082c4a;
    transition: width 0.5s ease;
}
.navbar-item.active .dropdown p:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: -5px;
    background-color: #42a5f5;
    /* Underline color */
    transition: width 0.5s ease;
}

.navbar-item.active p:hover:after {
    width: 100%;
}

.navbar-item:hover p::after {
    width: 100%;
}

.navbar-item:hover p{
    text-decoration: none;
}
.navbar-item .dropdown p{
    display: inline-block;
    position: relative;
    display: inline-block;
    font-size: 16px;
    padding: 0 0;
}

.anchor-color{
    color: black !important;
}
.anchor-color1{
    color: black !important;
}
.navbar-data > li > p {
color: black !important;
}